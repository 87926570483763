<template>
  <div class="edit-commission p-4">
    <h3 class="mb-4">Update Listing Commission</h3>
    <fd-form @submit="$emit('submit', form)">
      <fd-input
        v-model="form.commissionRate"
        class="col-12 mb-2"
        label="Commission"
        name="listingCommission"
        type="number"
        :validators="[
          validator.required,
          (val) => validator.minValue(val, 1),
          (val) => validator.maxValue(val, 100)
        ]"
      >
      </fd-input>
      <fd-button type="submit" class="main">Update</fd-button>
    </fd-form>
  </div>
</template>

<script>
import {
  required,
  minValue,
  maxValue
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  components: {},
  mixins: [],
  props: {
    payload: {
      type: Object
    }
  },
  data: function () {
    return {
      validator: {
        required,
        minValue,
        maxValue
      }
    };
  },
  computed: {
    form: {
      get() {
        return this.payload;
      },
      set(val) {
        return this.$emit("update:payload", { ...this.payload, val });
      }
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
